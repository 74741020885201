import styled from 'styled-components'
import { Flex } from '@renderbus/common/components'
import { Media, typography, spacing } from '@renderbus/common/theme'

import BackgroundImg from '../images/return50ps/background.png'
export const PageContainer = styled.div`
  width: 100%;
  background-image: url(${BackgroundImg});
  padding-bottom: 280px;
  ${Media.lessThan(Media.small)} {
    margin-top: -250px;
    padding-bottom: ${spacing.large};
  }
`
export const BannerWrapper = styled.div`
  padding: 200px 0 250px 0;
  ${Media.lessThan(Media.small)} {
    padding: 280px 0 ${spacing.large} 0;
  }
`
export const BannerContainer = styled(Flex)`
  color: white;
  width: 80%;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  ${Media.lessThan(Media.small)} {
    width: 90%;
    flex-direction: column;
    padding-top: 80px;
    justify-content: flex-start;
  }
`
export const WuhanContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  img {
    max-width: 100%;
    margin-bottom: ${spacing.base};
  }
  p {
    width: 100%;
    text-align: left;
  }
`
export const Text = styled.p`
  font-size: ${typography.h3};
  margin: 5px 0;
`
export const FightingImg = styled.img`
  ${Media.lessThan(Media.small)} {
    width: 40%;
    margin-top: ${spacing.small};
  }
`
export const Line = styled.span`
  width: 60px;
  height: 4px;
  margin: ${spacing.small} 0;
  background-color: white;
`
export const Block = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: ${spacing.base} 40px;
  background-image: linear-gradient(
    to right,
    rgba(79, 37, 193, 0.1),
    #4f25c2 55%,
    rgba(79, 37, 193, 0.1)
  );
  border: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(255, 174, 0, 0.1),
      #ffae00 50%,
      rgba(255, 174, 0, 0.1)
    )
    10 50 10;
  color: white;
  font-size: ${typography.h3};
  img {
    max-width: 100%;
  }
  ${Media.lessThan(Media.small)} {
    padding: ${spacing.base} ${spacing.base};
    border-radius: 4px;
  }
`
export const Slogan = styled(Flex)`
  margin: 50px 0 40px 0;
`
export const Title = styled.div`
  width: 80%;
  margin: 50px auto ${spacing.large} auto;
  ${Media.lessThan(Media.small)} {
    margin: ${spacing.large} auto ${spacing.base} auto;
    img {
      width: 40%;
    }
  }
`
export const Tips = styled.p`
  width: 80%;
  margin: ${spacing.large} auto 0 auto;
  color: #ae90ff;
  font-size: ${typography.h4};
  ${Media.lessThan(Media.small)} {
    margin-top: ${spacing.small};
  }
`
